import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'

export const ContentWrapper = styled.div`
  padding: 1rem;
  max-width: 70rem;
  margin-left: auto;
  margin-right: auto;
`

const PageWrapper = ({
  children,
  title,
  widthContentWrapper = true,
  description
}) => {
  return (
    <>
      <Helmet>
        {title && <title>{title}</title>}
        {description && <meta name="description" content={description} />}
      </Helmet>
      {widthContentWrapper ? (
        <ContentWrapper>{children}</ContentWrapper>
      ) : (
        children
      )}
    </>
  )
}

PageWrapper.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string,
  description: PropTypes.string
}

export default PageWrapper
