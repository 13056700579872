import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box } from '@smooth-ui/core-sc'
import { Link } from 'react-router-dom'

const Container = styled.footer`
  text-align: center;
  padding: 1rem;
  color: ${p => p.theme.colors.muted};
  font-size: 0.8rem;
`

const NavLink = styled(Link)`
  text-decoration: underline;
  display: inline-block;
  margin-right: 0.25rem;
  &:hover {
    text-decoration: none;
  }
`
const NavLinkA = styled.a`
  text-decoration: underline;
  display: inline-block;
  margin-right: 0.25rem;
  &:hover {
    text-decoration: none;
  }
`

const Footer = ({ menu, children }) => {
  return (
    <Container>
      <Box as="nav" mb={2}>
        {menu &&
          menu.map(({ to, label, labelAlt, href }, i) => {
            if (href) {
              return (
                <NavLinkA key={i} href={href}>
                  {labelAlt || label}
                </NavLinkA>
              )
            }
            return (
              <NavLink key={i} to={to}>
                {labelAlt || label}
              </NavLink>
            )
          })}
      </Box>
      <Box as="p">{children}</Box>
    </Container>
  )
}

Footer.propTypes = {
  menu: PropTypes.array
}

export default Footer
