const env = process.env

const isProduction = env['NODE_ENV'] === 'production'

const stores = {
  wt: {
    themeColor: '#f04766',
    devLogo: '/wt/logo.svg',
    frontPageId: 15439,
    appName: 'Webtrening as',
    agreementUrl: '/faq',
    enableUsernameLogin: true,
    middleMenu: [
      {
        to: {
          pathname: '/faq',
          state: {
            postTitle: 'Spørsmål og svar'
          }
        },
        label: 'Spørsmål og svar'
      }
    ],
    middleMenuUser: []
  },
  theroom: {
    themeColor: '#5e345e',
    devLogo: '/logo.svg',
    frontPageId: 30683,
    appName: 'The Room Online as',
    agreementUrl: '/faq-room',
    middleMenu: [
      {
        to: {
          pathname: '/faq-room',
          state: {
            postTitle: 'Spørsmål og svar'
          }
        },
        label: 'Spørsmål og svar'
      }
    ],
    middleMenuUser: [
      {
        href: 'https://theroom.no/',
        label: 'Våre Studio'
      },
      {
        href: 'https://www.theroomshop.no/',
        label: 'Vår nettbutikk'
      }
    ]
  }
}

const extra = stores[env.REACT_APP_STORE_ID]

export default {
  isProduction,
  token: 'APP_AUTH_TOKEN',
  logoUrl: isProduction ? '/logo.svg' : extra.devLogo,
  kcalPerSecond: 0.13,
  ...extra
}
