import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import Footer from 'components/Layout/Footer'

import config from 'config'

const AppFooter = ({ menu }) => {
  const menuFooter =
    menu && menu.filter(({ label, hideFooter }) => !hideFooter && !!label)
  const year = new Date().getFullYear()
  return (
    <Footer menu={menuFooter}>
      Copyright {config.appName} {year}
    </Footer>
  )
}

AppFooter.propTypes = {
  menu: PropTypes.array
}

export default withRouter(AppFooter)
