import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { hot } from 'react-hot-loader/root'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { HelmetProvider } from 'react-helmet-async'
import { ApolloProvider } from '@apollo/react-hooks'

import App, { UPDATE_EVENT } from 'containers/App/App'
import theme from 'helpers/theme'
import bodyStyle from 'helpers/bodyStyle'
import { TokenProvider } from 'helpers/tokenContext'

const GlobalStyle = createGlobalStyle`${bodyStyle}`

export const authToken = 'appToken3'

export { UPDATE_EVENT }

const Root = ({ client, ...props }) => {
  const [token, updateToken] = useState(localStorage.getItem(authToken))
  const setToken = useCallback(
    async t => {
      if (t) {
        localStorage.setItem(authToken, t)
      } else {
        localStorage.removeItem(authToken)
      }
      updateToken(t)
      return client.resetStore()
    },
    [client]
  )
  const tokenValue = {
    token,
    setToken
  }
  return <RootUniversal client={client} {...props} tokenValue={tokenValue} />
}

export const RootUniversal = ({
  client,
  helmetContext,
  tokenValue,
  emitter
}) => (
  <HelmetProvider context={helmetContext}>
    <GlobalStyle />
    <TokenProvider value={tokenValue}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <App emitter={emitter} />
        </ThemeProvider>
      </ApolloProvider>
    </TokenProvider>
  </HelmetProvider>
)

Root.propTypes = {
  client: PropTypes.object
}

export default hot(Root)
