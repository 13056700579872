import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import config from 'config'

const Container = styled(Link)`
  display: inline-block;
  & img {
    display: block;
    height: 3.4rem;
    width: auto;
  }
`

const Logo = ({ fontSize = 2, mini }) => {
  return (
    <Container to="/">
      <img src={config.logoUrl} alt={config.name} />
    </Container>
  )
}

Logo.propTypes = {
  fontSize: PropTypes.number
}

export default Logo
