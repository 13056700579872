import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import { Box } from '@smooth-ui/core-sc'

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`
const Spinner = styled.div`
  display: inline-block;
  position: relative;
  height: ${p => p.size}em;
  width: ${p => p.size}em;
  font-size: ${p => p.size}em;
  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${p => p.size}em;
    height: ${p => p.size}em;
    margin-top: -${p => p.size / 2}em;
    margin-left: -${p => p.size / 2}em;
    border-radius: 50%;
    border: ${p => p.size / 5}rem solid
      ${p => p.theme.colors[p.variant || 'primary']};
    border-top-color: transparent;
    animation: ${rotate} 1s linear infinite;
  }
`
const SpinnerContent = styled.div`
  padding-top: 1rem;
`

const LoadingSpinner = ({ children, size = 2, variant, ...rest }) => {
  return (
    <Box p={5} pl={2} pr={2} textAlign="center" {...rest}>
      <Spinner size={size} variant={variant} />
      {children && <SpinnerContent>{children}</SpinnerContent>}
    </Box>
  )
}

export const LoadingSpinnerInline = ({
  children,
  size = 1.2,
  variant = 'success',
  ...rest
}) => {
  return (
    <Box display="flex" alignItems="center" {...rest}>
      <Spinner size={size} variant={variant} />
      {children && (
        <Box ml={2} flex="1">
          {children}
        </Box>
      )}
    </Box>
  )
}

LoadingSpinner.propTypes = {
  children: PropTypes.any
}

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LoadingSpinnerFullscreen = props => (
  <Container>
    <LoadingSpinner {...props} />
  </Container>
)

export default LoadingSpinner
