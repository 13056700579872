import { gql } from 'apollo-boost'

export const QUERY_ACCESS = gql`
  query Me {
    me {
      id
      email
      membership_level
    }
  }
`

export const MUTATION_ADD = gql`
  mutation addTrainginLog(
    $title: String!
    $kcal: Int!
    $seconds: Int!
    $postId: Int
  ) {
    addTrainginLog(
      title: $title
      kcal: $kcal
      seconds: $seconds
      postId: $postId
    ) {
      id
      title
      date
      postId
      kcal
      seconds
    }
  }
`

export const QUERY_LIST_FAVORITES = gql`
  query listFavorites {
    listFavorites {
      id
      title
      created
      postId
    }
  }
`

export const MUTATION_REMOVE_FAVORITE = gql`
  mutation removeFavorite($id: ID!) {
    removeFavorite(id: $id) {
      id
      __typename
    }
  }
`

export const MUTATION_ADD_FAVORITE = gql`
  mutation addFavorite($title: String!, $postId: Int!) {
    addFavorite(title: $title, postId: $postId) {
      id
      title
      created
      postId
    }
  }
`
