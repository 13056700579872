import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box, Button } from '@smooth-ui/core-sc'
import { Route, Link } from 'react-router-dom'
import { Bars } from 'styled-icons/fa-solid/Bars'

import Logo from './Logo'

const Container = styled(Box)`
  background: ${p => p.theme.colors.gray1};
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
`
const LogoBox = styled(Box)`
  color: ${p => p.theme.colors.primary};
`
const NavButton = styled(Box)`
  color: ${p => (p.active ? p.theme.colors.text : p.theme.colors.primary)};
  padding: 0.5rem;
  display: inline-block;
  font-weight: bold;
  font-size: 1.1rem;
  font-weight: 900;
  font-style: italic;
  text-transform: uppercase;
`

const NavLink = ({ label, icon, to, activeOnlyWhenExact, href }) => {
  const path = to && to.pathname ? to.pathname : to
  if (href) {
    return (
      <NavButton as="a" href={href}>
        {icon} {label}
      </NavButton>
    )
  }
  return (
    <Route
      path={path}
      exact={activeOnlyWhenExact}
      children={({ match }) => (
        <NavButton as={Link} to={to} active={match}>
          {icon} {label}
        </NavButton>
      )}
    />
  )
}

const Header = ({ menu, sidebarVisible, toggleSidebar }) => {
  return (
    <Container as="header">
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        flex="1"
      >
        <LogoBox flex="1" mr={4}>
          <Logo />
        </LogoBox>
        <Box>
          <Box display={{ xs: 'none', md: 'flex' }}>
            {menu &&
              menu.map((item, i) => {
                return <NavLink key={i} {...item} />
              })}
          </Box>
          <Button
            onClick={toggleSidebar}
            variant="primary"
            display={{ xs: 'flex', md: 'none' }}
          >
            <Box alignItems="center" display="flex">
              <Bars size="20" />
            </Box>
          </Button>
        </Box>
      </Box>
    </Container>
  )
}

Header.propTypes = {
  homeLink: PropTypes.string
}

export default Header
