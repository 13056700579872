const mypage = '/min-side'

export default {
  home: '/',
  posts: '/trening',
  search: '/sok',
  post: '/film',
  postRedirect: '/videresender',
  traningTypes: '/treningsformer',
  courses: '/kurs',
  live: '/live',
  liveTest: '/live-testside',
  category: '/kategori',
  signup: '/meld-deg-inn',
  logout: '/logg-ut',
  login: '/logg-inn',
  forgotPassword: '/glemt-passord',
  resetPassword: '/nullstill-passord',
  mypage,
  mypageMembership: `${mypage}/medlemskap`,
  mypagePersona: `${mypage}/mine-personalia`,
  mypageChangeEmail: `${mypage}/ny-epost`,
  mypageLog: `${mypage}/treningsdagbok`,
  mypagePlaylist: `${mypage}/dine-lister`,
  mypagePaymentMethod: `${mypage}/bytt-betalingskort`,
  mypageFavorites: `/favoritter`
}
