import 'core-js/stable'
import 'regenerator-runtime/runtime'
import React from 'react'
import { render, hydrate } from 'react-dom'
import ApolloClient, { InMemoryCache } from 'apollo-boost'
import { BrowserRouter as Router } from 'react-router-dom'
import { loadableReady } from '@loadable/component'
import { persistCache } from 'apollo-cache-persist'
import Emitter from 'tiny-emitter'

import trackGMT from 'helpers/gtm'
import * as serviceWorker from './serviceWorker'

import Root, { authToken, UPDATE_EVENT } from 'Root'
import config from 'config'

const cache = new InMemoryCache().restore(window.__APOLLO_STATE__)

const dest = document.getElementById('root')

const emitter = new Emitter()

async function renderMain(func) {
  if (window.__APOLLO_STATE__) {
    await loadableReady()
  }
  await persistCache({
    cache,
    storage: window.localStorage
  })
  const client = new ApolloClient({
    uri: '/graphql',
    cache,
    request: operation => {
      const token = localStorage.getItem(authToken)
      const authorizationHeader = token ? `Bearer ${token}` : null
      operation.setContext({
        headers: {
          authorization: authorizationHeader
        },
        fetchOptions: {
          credentials: 'include'
        }
      })
    },
    onError: ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        const errors = graphQLErrors.map(({ message }) => message).join(',')
        console.log('graphQLErrors', errors)
        global.rollbar && global.rollbar.error(graphQLErrors)
        trackGMT({
          event: 'custom',
          category: 'Error',
          action: `GraphQLErrors (${errors})`,
          label: global.location.pathname
        })
      }
      if (networkError) {
        console.log('networkError', networkError)
        global.rollbar && global.rollbar.error(networkError)
        trackGMT({
          event: 'custom',
          category: 'Error',
          action: `NetworkError (${JSON.stringify(networkError)})`,
          label: global.location.pathname
        })
      }
    }
  })
  client.defaultOptions = {
    watchQuery: {
      fetchPolicy: 'cache-and-network'
    },
    query: {
      fetchPolicy: 'cache-and-network'
    }
  }
  func(
    <Router>
      <Root client={client} emitter={emitter} />
    </Router>,
    dest
  )
}

renderMain(config.isProduction && window.__APOLLO_STATE__ ? hydrate : render)

serviceWorker.register({
  onSuccess: () => {
    console.log('serviceWorker onSuccess')
  },
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting
    console.log('serviceWorker onUpdate', waitingServiceWorker, registration)
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', event => {
        if (event.target.state === 'activated') {
          window.location.reload()
        }
      })
      emitter.emit(UPDATE_EVENT, {
        ok: () => {
          console.log('ok pressed')
          waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
        },
        cancel: () => console.log('cancel pressed'),
        message:
          'En ny versjon av nettsiden er tilgjennlig, vil du oppdatere nå?'
      })
      window.addEventListener('beforeunload', event => {
        console.log('close/reload')
        waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
      })
    }
  }
})
