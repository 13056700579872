import React, { useContext, useEffect, Component, useState } from 'react'
import loadable from '@loadable/component'
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  Link,
  useHistory
} from 'react-router-dom'
import useToggle from '@rooks/use-toggle'
import { gql } from 'apollo-boost'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Box, Button } from '@smooth-ui/core-sc'
import { Search as SearchIcon } from 'styled-icons/fa-solid/Search'

import TokenContext from 'helpers/tokenContext'

import trackGMT from 'helpers/gtm'
import routeConfig from 'routeConfig'
import Layout from 'components/Layout/Layout'
import PageWrapper from 'components/PageWrapper/PageWrapper'
import { PageTitle } from 'components/Elements/index'
import NotifyDialog from 'components/Layout/NotifyDialog'

import AppHeader from './AppHeader'
import AppFooter from './AppFooter'
import AppSidebar from './AppSidebar'
import { QUERY_ACCESS } from 'helpers/queries'

import config from 'config'

const Posts = loadable(() =>
  import(/* webpackChunkName: "Posts" */ '../Posts/Posts')
)
const Category = loadable(() =>
  import(/* webpackChunkName: "Category" */ '../Category/Category')
)
const Search = loadable(() =>
  import(/* webpackChunkName: "Search" */ '../Search/Search')
)
const Post = loadable(() =>
  import(/* webpackChunkName: "Post" */ '../Post/Post')
)
const PostRedirect = loadable(() =>
  import(/* webpackChunkName: "PostRedirect" */ '../PostRedirect/PostRedirect')
)
const Page = loadable(() =>
  import(/* webpackChunkName: "Page" */ '../Page/Page')
)
const LivePage = loadable(() =>
  import(/* webpackChunkName: "LivePage" */ '../LivePage/LivePage')
)
const Login = loadable(() =>
  import(/* webpackChunkName: "Login" */ '../Login/Login')
)
const ForgotPassword = loadable(() =>
  import(/* webpackChunkName: "ForgotPassword" */ '../Login/ForgotPassword')
)
const ResetPassword = loadable(() =>
  import(/* webpackChunkName: "ResetPassword" */ '../Login/ResetPassword')
)
const MyPage = loadable(() =>
  import(/* webpackChunkName: "MyPage" */ '../MyPage/MyPage')
)
const EditInfo = loadable(() =>
  import(/* webpackChunkName: "EditInfo" */ '../MyPage/EditInfo')
)
const ConfirmEmail = loadable(() =>
  import(/* webpackChunkName: "ConfirmEmail" */ '../MyPage/ConfirmEmail')
)
const MemberShip = loadable(() =>
  import(/* webpackChunkName: "MemberShip" */ '../MyPage/MemberShip/MemberShip')
)
const TrainingLogs = loadable(() =>
  import(/* webpackChunkName: "TrainingLogs" */ '../MyPage/TrainingLogs')
)
const Playlist = loadable(() =>
  import(/* webpackChunkName: "Playlist" */ '../MyPage/Playlist')
)
const PaymentMethod = loadable(() =>
  import(/* webpackChunkName: "PaymentMethod" */ '../MyPage/PaymentMethod')
)
const Favorites = loadable(() =>
  import(/* webpackChunkName: "Favorites" */ '../Favorites/Favorites')
)

const Signup = loadable(() =>
  import(/* webpackChunkName: "Signup" */ '../Signup/Signup')
)
const Home = loadable(() =>
  import(/* webpackChunkName: "Home" */ '../Home/Home')
)

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { token } = useContext(TokenContext)
  return (
    <Route
      {...rest}
      render={props =>
        token ? <Component {...props} /> : <Redirect to={routeConfig.login} />
      }
    />
  )
}

const logoutMutation = gql`
  mutation logout {
    logout {
      message
      success
    }
  }
`

const Logout = () => {
  const { setToken } = useContext(TokenContext)
  const [logout] = useMutation(logoutMutation)
  useEffect(() => {
    async function run() {
      await logout()
      setToken(null)
      trackGMT({
        event: 'custom',
        category: 'Login',
        action: 'Logout success'
      })
    }
    run()
  }, [setToken, logout])
  return null
}

const menuUser = [
  {
    to: routeConfig.posts,
    label: 'Nyeste filmer'
  },
  {
    to: routeConfig.mypageFavorites,
    label: 'Favoritter'
  },
  {
    to: {
      pathname: routeConfig.traningTypes,
      state: {
        postTitle: 'Treningsformer'
      }
    },
    label: 'Treningsformer'
  },
  {
    to: {
      pathname: routeConfig.live,
      state: {
        postTitle: 'Live'
      }
    },
    label: 'Live'
  },
  {
    to: {
      pathname: routeConfig.courses,
      state: {
        postTitle: 'Kurs'
      }
    },
    label: 'Kurs'
  },
  {
    to: routeConfig.mypage,
    label: 'Min side'
  },
  {
    to: routeConfig.logout,
    label: 'Logg ut',
    hideFooter: true
  },
  {
    to: routeConfig.search,
    label: <SearchIcon size="18" />,
    labelAlt: 'Søk'
  }
]

const menuGuest = [
  {
    to: routeConfig.signup,
    label: 'Registrer deg'
  },
  ...config.middleMenu,
  {
    to: routeConfig.login,
    label: 'Logg inn'
  },
  {
    to: routeConfig.search,
    icon: <SearchIcon size="18" />
  }
]

const UserLevelWarning = withRouter(({ history: { replace } }) => {
  const { token } = useContext(TokenContext)
  const { data } = useQuery(QUERY_ACCESS, {
    ssr: false
  })
  useEffect(() => {
    if (token && data && !data.me) {
      async function check() {
        // Token not valid anymore, logout user
        replace(routeConfig.logout)
      }
      check()
    }
  }, [data, replace, token])
  if (data && data.me && data.me.membership_level < 1) {
    return (
      <Box
        p={2}
        textAlign="center"
        backgroundColor="primary"
        color="white"
        fontSize="0.8rem"
      >
        Din bruker er nedgradert og har ikke lenger tilgang. Gå til{' '}
        <Link
          to={routeConfig.mypageMembership}
          style={{ textDecoration: 'underline' }}
        >
          Min side
        </Link>{' '}
        for å fikse det.
      </Box>
    )
  }
  return null
})

const RoutingScroll = withRouter(({ location }) => {
  useEffect(() => {
    if (global.scrollTo) {
      global.scrollTo(0, 0)
    }
  }, [location.pathname])
  return null
})

export const UPDATE_EVENT = 'shouldUpdate'

const Notify = ({ emitter }) => {
  const [notify, setNotify] = useState(null)
  useEffect(() => {
    emitter.on(UPDATE_EVENT, obj => {
      setNotify(obj)
      trackGMT({
        event: 'custom',
        category: 'ServiceWorker',
        action: 'Show update message'
      })
    })
    return () => {
      emitter.off(UPDATE_EVENT)
    }
  }, [setNotify, emitter])
  const onUpdate = () => {
    trackGMT({
      event: 'custom',
      category: 'ServiceWorker',
      action: 'Ok update'
    })
    notify.ok()
  }
  const onCancel = () => {
    setNotify(null)
    trackGMT({
      event: 'custom',
      category: 'ServiceWorker',
      action: 'Cancel update'
    })
    notify.cancel()
  }
  if (notify) {
    return (
      <NotifyDialog
        title={notify.message}
        onOk={onUpdate}
        onCancel={onCancel}
      />
    )
  }
  return null
}

const App = ({ emitter }) => {
  const { token } = useContext(TokenContext)
  const [sidebarVisible, toggleSidebar] = useToggle(false)
  const menu = token ? menuUser : menuGuest
  const menuProps = {
    menu,
    sidebarVisible,
    toggleSidebar
  }
  return (
    <Layout
      header={<AppHeader {...menuProps} />}
      infoBar={token ? <UserLevelWarning /> : null}
      sidebar={<AppSidebar {...menuProps} />}
      footer={<AppFooter menu={[...menu, ...config.middleMenuUser]} />}
    >
      <ErrorBoundary>
        <Switch>
          <Route path={routeConfig.home} exact component={Home} />
          <Route path={`${routeConfig.signup}/:coupon?`} component={Signup} />
          <Route path={routeConfig.login} component={Login} />
          <Route path={routeConfig.forgotPassword} component={ForgotPassword} />
          <Route
            path={`${routeConfig.resetPassword}/:token`}
            component={ResetPassword}
          />
          <PrivateRoute path={routeConfig.mypage} component={MyPage} exact />
          <PrivateRoute path={routeConfig.mypagePersona} component={EditInfo} />
          <PrivateRoute
            path={`${routeConfig.mypageChangeEmail}/:token`}
            component={ConfirmEmail}
          />
          <PrivateRoute
            path={routeConfig.mypageMembership}
            component={MemberShip}
          />
          <PrivateRoute
            path={`${routeConfig.mypageLog}/:year?/:month?`}
            component={TrainingLogs}
          />
          <PrivateRoute
            path={routeConfig.mypagePlaylist}
            component={Playlist}
          />
          <PrivateRoute
            path={routeConfig.mypagePaymentMethod}
            component={PaymentMethod}
          />
          <PrivateRoute
            path={`${routeConfig.mypageFavorites}/:after?`}
            component={Favorites}
          />
          <PrivateRoute path={routeConfig.logout} component={Logout} />
          <Route
            path={`${routeConfig.search}/:phrase?/:after?`}
            component={Search}
          />
          <Route path={`${routeConfig.posts}/:after?`} component={Posts} />
          <Route
            path={`${routeConfig.category}/:slug/:after?`}
            component={Category}
          />
          <Route path={`${routeConfig.post}/:slug?`} component={Post} />
          <Route
            path={`${routeConfig.postRedirect}/:id?`}
            component={PostRedirect}
          />
          <Route path={`${routeConfig.live}`} component={LivePage} />
          <Route path={`${routeConfig.liveTest}`} component={LivePage} />
          <Route path={'/:slug'} component={Page} />
        </Switch>
        <RoutingScroll />
        {emitter && <Notify emitter={emitter} />}
      </ErrorBoundary>
    </Layout>
  )
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false
    }
  }
  static getDerivedStateFromError() {
    return { hasError: true }
  }
  componentDidCatch(error, info) {
    if (global.Rollbar) {
      global.Rollbar.error(error, info)
    }
    console.log('componentDidCatch', error, info)
  }
  render() {
    const { children } = this.props
    if (this.state.hasError) {
      return (
        <PageWrapper title="Noe gikk galt">
          <PageTitle>Obs, noe gikk galt</PageTitle>
          <Box mb="3">
            Din innlastede versjon av nettsiden er utdatert så vi trenger å
            laste inn siden på nytt. Vi baklager dette!
          </Box>
          <ErrorButton>Last inn siden på nytt</ErrorButton>
        </PageWrapper>
      )
    }
    return children
  }
}

const ErrorButton = props => {
  const { push } = useHistory()
  const action = () => {
    push('/')
    setTimeout(() => window.location.reload(true), 50)
  }
  return <Button onClick={action} {...props} />
}

export default App
