import React from 'react'
import {
  Box,
  Button,
  Input as SmoothInput,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Text,
  Select as SmoothSelect
} from '@smooth-ui/core-sc'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import LoadingSpinner from '../Spinner/Spinner'

export const inputCss = css`
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.2);
  border-radius: 0.2rem;
`

const CardBodyStyled = styled(CardBody)`
  ${p =>
    p['data-fade'] &&
    css`
      max-height: 13rem;
      overflow: hidden;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(
          to top,
          #f2f2f2 10%,
          rgba(229, 229, 229, 0) 100%
        );
        height: 4rem;
      }
    `}
`
const CardFooter = styled.div`
  padding: 0 1.25rem 1rem;
`

export const FeatureCard = ({
  title,
  text,
  image,
  imageEl,
  hasFade,
  children,
  link,
  footer,
  ...props
}) => {
  const linkProps = link ? { to: link, as: Link } : {}
  return (
    <Card width="100%" mb={3} border="none" background="#f2f2f2" {...props}>
      {imageEl}
      {image && <CardImg src={image} {...linkProps} />}
      <CardBodyStyled data-fade={hasFade}>
        <CardTitle mb={0} {...linkProps}>
          <Text variant="h2" fontSize="1.4rem" mb={1} color="primary">
            {title}
          </Text>
        </CardTitle>
        {text && (
          <Text forwardedAs="p" fontSize="1.1rem">
            {text}
          </Text>
        )}
        {children}
      </CardBodyStyled>
      {footer && <CardFooter>{footer}</CardFooter>}
    </Card>
  )
}

export const Input = styled(SmoothInput)`
  border: none;
  ${inputCss}
`
const Select = styled(SmoothSelect)`
  border: none;
  ${inputCss}
`

export const FormField = ({ field, ...props }) => (
  <Box mb={3}>
    <Input {...field} {...props} />
  </Box>
)

export const FormFieldSelect = ({ field, ...props }) => (
  <Box mb={3}>
    <Select {...field} {...props} />
  </Box>
)

export const PageTitle = props => (
  <Text variant="h1" fontSize="1.6rem" mb={2} fontWeight="900" {...props} />
)

export const SubTitle = props => (
  <Box as="h2" fontSize="1.2rem" fontWeight="bold" mb={2} {...props} />
)

export const P = props => <Box as="p" mb={2} {...props} />

export const A = styled(Box)`
  color: ${p => p.theme.colors.primary};
  text-decoration: underline;
  border: none;
  background: none;
  &:hover,
  &:active {
    text-decoration: none;
    cursor: pointer;
  }
`

export const BtnBar = ({ children }) => (
  <Box p={1} display="flex" justifyContent="space-between">
    {children}
  </Box>
)

export const Paper = props => (
  <Box
    backgroundColor="white"
    mb={2}
    boxShadow="0 0 1rem rgba(0,0,0,.1)"
    p={2}
    {...props}
  />
)

export const GrayBox = props => (
  <Box backgroundColor="gray1" mb={2} p={3} {...props} />
)

export const Row = props => <Box row mx={-2} {...props} />

export const Col = props => <Box row px={2} {...props} />

export const SpinnerButton = ({
  working,
  text,
  disabled,
  textWorking,
  ...props
}) => (
  <Button
    variant="primary"
    disabled={disabled || working}
    display="flex"
    alignItems="center"
    justifyContent="center"
    {...props}
  >
    {working ? (
      <>
        <Box>{textWorking || text}</Box>
        <LoadingSpinner p={0} variant="info" lineHeight="1" size="1.2" />
      </>
    ) : (
      <Box>{text}</Box>
    )}
  </Button>
)
