import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  background: white;
  color: ${p => p.theme.colors.text};
  display: flex;
  width: 100%;
`
const Main = styled.main`
  flex: 1;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  max-width: 100%;
`
const Content = styled.main`
  flex: 1;
`

const Layout = ({ children, sidebar, header, footer, infoBar }) => (
  <Container>
    {sidebar}
    <Main>
      {infoBar}
      {header}
      <Content>{children}</Content>
      {footer}
    </Main>
  </Container>
)

Layout.propTypes = {
  children: PropTypes.any.isRequired,
  header: PropTypes.any
}

export default Layout
